import { defineComponent, reactive } from "vue";

import { Field as VField, Form as VForm } from 'vee-validate';

import { authService }      from "@services/auth.service";
import { AuthRoutesEnum }   from "../../router";
import { authStore }        from '../../store';
import VI18nError           from "@components/VI18nError/VI18nError.vue";
import { OrdersRoutesEnum } from "@/modules/orders/router";
import { createPusher }     from "@/pusher";
import { configuration } from "@plugins/Config-plugin";

interface FormState {
    username: string;
    password: string;
    rememberMe: boolean;
}

export default defineComponent( {
    components: {
        VForm,
        VField,
        VI18nError
    },

    setup() {
        const images = configuration.loginLogos;


        const state = reactive<FormState>({
            username: null,
            password: null,
            rememberMe: false
        });

        return {
            images, 
            ...state,
            forgotPasswordRoute: AuthRoutesEnum.FORGOTTEN_PWD,
            signUpRoute: AuthRoutesEnum.SIGN_UP
        }
    },

    methods: {
        async doLogin({ username: email, password }: FormState) {
            try {
                const response = await authService.login({ email, password })

                this.$localStorage.clearAll();
                this.$localStorage.token = response.access_token;
                createPusher( response.access_token );

                await authStore.actions.getMe();

                this.$router.replace({ name: OrdersRoutesEnum.ORDERS_LIST })
            } catch (error) {
                console.debug("error", error);
                this.$errorMessage(this.$t('login.error_message'));
            }
        },
    }
})
