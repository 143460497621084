
import { computed, defineComponent, ref } from 'vue';
import { useField, useForm }              from "vee-validate";
import VInputText                         from "@components/VInputText/VInputText.vue";
import { domainService }                  from "@services/domain.service";
import { BaseApiFilter }                  from "@/model/filters/BaseApiFilter";
import { AddressAutocomplete }            from "@/components";
import { PaymentType, UserType }          from "@/model/Entity";
import { authService }                    from "@services/auth.service";
import { localStorageSVC }                from "@plugins/LocalStorage-plugin";
import { createPusher }                   from "@/pusher";
import { authStore }                      from "@/modules/auth/store";
import { useRouter }                      from "vue-router";
import { OrdersRoutesEnum }               from "@/modules/orders/router";
import { useMessage }                     from "@plugins/Messages-plugin";
import { useI18n }                        from "vue-i18n";

export default defineComponent( {
  components: {
    AddressAutocomplete,
    VInputText
  },
  setup() {
    const { successMessage, errorMessage } = useMessage();
    const { t }                            = useI18n()
    const business_city                    = ref();
    const business_address                 = ref();

    //#region City Autocomplete TODO: Component
    const citiesSuggestions = ref( [] );

    async function searchCity( { query } ) {
      const response = await domainService.cities(
          BaseApiFilter.fromGlobal(
              query,
              {
                sortField: 'name',
                sortOrder: 1
              }
          )
      );

      const q = query.toLowerCase();

      /**
       * Metto in evidenza le città che iniziano con la stringa inserita
       */
      response.data.sort( ( a, b ) => {
        const a_StartsWithQ = a.name.toLowerCase().startsWith( q );
        const b_StartsWithQ = b.name.toLowerCase().startsWith( q );

        if (a_StartsWithQ && !b_StartsWithQ) {
          return -1;
        } else if (!a_StartsWithQ && b_StartsWithQ) {
          return 1;
        } else {
          return a.name.localeCompare( b.name )
        }
      } )

      citiesSuggestions.value = [ ...response.data ];
    }

    function setCity( value ) {
      business_city.value = value;
      setFieldValue( 'business_city_name', value.name );
      setFieldValue( 'business_city_id', value.id );
    }

    //#endregion

    //#region Address
    const geocodeResult = ref<google.maps.GeocoderResult>( null );

    function getState( g: google.maps.GeocoderResult ) {
      return g.address_components
              .find( x => x.types?.includes( "country" ) )
          ?.long_name;
    }

    function getProvince( g: google.maps.GeocoderResult ) {
      return g.address_components
              .find( x => x.types?.includes( "administrative_area_level_2" ) )
          ?.short_name;
    }

    function getPostalCode( g: google.maps.GeocoderResult ) {
      return g.address_components
              .find( x => x.types?.includes( "postal_code" ) )
          ?.short_name;
    }

    function onSelectAddress( item: google.maps.GeocoderResult ) {
      geocodeResult.value = item;
      setFieldValue( 'business_address', item?.formatted_address )
      setFieldValue( 'business_province', getProvince( item ) )
      setFieldValue( 'business_postal_code', getPostalCode( item ) )
      setFieldValue( 'business_state', getState( item ) )
    }

    //#endregion

    const validationSchema = {
      name   : 'required',
      surname: 'required',
      email  : 'required|email',

      password        : 'required|min:6',
      confirm_password: 'required|confirmPwd:@password',

      business_name : 'required',
      business_tel  : 'required',
      business_email: 'required|email',

      business_pec: null,
      business_sdi: null,
      business_vat: 'requiredThisOr:business_fc',
      business_fc : 'requiredThisOr:business_vat',

      business_city_name  : 'required',
      business_address    : 'required',
      business_postal_code: 'required',
      business_province   : null,
      business_state      : null,
    }

    const { values, setFieldValue, errors, handleSubmit } = useForm( { validationSchema } )

    const onSubmit = handleSubmit( ( form ) => {
          const body = {
            name    : form.name,
            surname : form.surname,
            email   : form.email,
            password: form.password,
            entity  : {
              name   : form.name,
              surname: form.surname,

              email               : form.business_email,
              business_name       : form.business_name,
              business_address    : form.business_address,
              business_city_name  : business_city.value.name,
              business_city_id    : business_city.value.id,
              business_province   : form.business_province,
              business_state      : form.business_state,
              business_postal_code: form.business_postal_code,
              business_tel        : form.business_tel,
              sdi                 : form.business_sdi,
              pec                 : form.business_pec,
              vat                 : form.business_vat,
              fiscal_code         : form.business_fc,
              type                : UserType.CUSTOMER,
              payment_type        : PaymentType.ANTICIPATED,
            }
          }
          signUp( body );
        },
        console.error
    );

    const { value: pwd, errors: pwdErrors }   = useField( 'password' )
    const { value: cpwd, errors: cpwdErrors } = useField( 'confirm_password' )

    const { value: vat } = useField( 'business_vat' )
    const { value: fc }  = useField( 'business_fc' )

    useField( 'business_city_name' )

    const hasVatOrFcError = computed( () => {
      const { business_fc, business_vat } = errors.value as any;
      return business_fc && business_vat
    } )

    function hasError( fieldName: string ) {
      return !!errors.value?.[fieldName];
    }

    const router       = useRouter();
    const isSubmitting = ref( false );

    async function signUp( entity ) {
      try {
        isSubmitting.value = true;
        const response     = await authService.signup( entity );

        localStorageSVC.clearAll();
        localStorageSVC.token = response.access_token;

        createPusher( response.access_token );

        await authStore.actions.getMe();

        goToApp();
        displaySuccessMessage();
      } catch (e) {
        console.error( e );

        const errors        = e.errors || e.data.errors;
        const fieldsInError = Object.keys( errors );

        if (fieldsInError?.length) {
          fieldsInError.forEach( k => {
            errorMessage( errors[k]?.join( ' ' ), "Attenzione" );
          } );
        }
      } finally {
        isSubmitting.value = false;
      }
    }

    function goToApp() {
      router.replace( { name: OrdersRoutesEnum.ORDERS_LIST } )
    }

    function displaySuccessMessage() {
      successMessage(
          t( 'registration.welcome' ),
          t( 'registration.succesfull' )
      )
    }

    return {
      business_city,
      citiesSuggestions,
      searchCity,
      setCity,

      business_address,
      onSelectAddress,

      values,
      errors,
      hasError,
      vat, fc,
      hasVatOrFcError,

      pwd, pwdErrors,
      cpwd, cpwdErrors,

      onSubmit,
      isSubmitting

    }
  }
} )
