
import { useMessage } from "@plugins/Messages-plugin";
import { authService } from "@services/auth.service";
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import { AuthRoutesEnum } from "../../router";


export default defineComponent({
  computed: {
    loginRoute() {
      return AuthRoutesEnum.SIGN_IN;
    }
  },
  setup() {
    const {
      errorMessage
    } = useMessage();

    const route = useRoute();

    const newPassword = ref("");
    const confirmPassword = ref("");

    const isLoading = ref(false);
    const accomplished = ref(false);

    async function submit() {
      if (!isValidPassword(newPassword.value, confirmPassword.value)) {
        errorMessage("Password non valida");
        return;
      }

      try {
        isLoading.value = true;

        await authService.setResetPassword({
          token: route.params.token as string,
          password: newPassword.value,
          confirm_password: confirmPassword.value
        })

        accomplished.value = true;
      } catch (error) {
        errorMessage("Errore nell'invio della password, riprova");
      } finally {
        isLoading.value = false;
      }
    }

    function isValidPassword(pwd: string, confirm: string) {
      return (
        pwd.length >= 8 &&
        /[A-Z]/.test(pwd) &&
        /[a-z]/.test(pwd) &&
        /\d/.test(pwd) &&
        /\W/.test(pwd) &&
        pwd === confirm
      );
    }

    return {
      accomplished,
      newPassword,
      confirmPassword,
      isLoading,
      submit
    };
  }
})

