
import { useMessage } from "@plugins/Messages-plugin";
import { authService } from "@services/auth.service";
import { defineComponent, ref } from "vue";


export default defineComponent({
  setup() {
    const {
      errorMessage
    } = useMessage();

    const emailSent = ref(false);

    const email = ref("");

    const isSending = ref(false);

    async function sendEmail() {
      if (!isValidEmail(email.value)) {
        errorMessage("Inserire un'email valida");
        return;
      }

      try {
        isSending.value = true;

        await authService.sendResetPassword({
          email: email.value,
          is_mobile: false
        })

        emailSent.value = true;

      } catch (error) {
        errorMessage("Errore nell'invio dell'email, riprova");
      } finally {
        isSending.value = false;
      }
    }

    function isValidEmail(email: string): boolean {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    return {
      emailSent,
      email,
      sendEmail
    }
  }
})
